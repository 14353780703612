<template>
  <div class="about">
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeWwY8aIZONXD_k_bqUss1Ov1Axg7JoF9Bdb3uH0hifATZKnA/viewform?embedded=true"
      width="640"
      height="520"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading…</iframe
    >
  </div>
</template>

<script>
export default {
  name: "Feedback"
};
</script>
<style lang="scss" scoped>
.about {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
